














import Vue from 'vue';
import { Slide } from '@/types';

export default Vue.extend({

  data() {
    return {
      slides: [
        {
          text1: 'Innehalten',
          text2: 'Zeit für Orientierung und neue Ideen',
        },
        {
          text1: 'Führen',
          text2: 'Wie ich mich selbst führe, so führe ich andere',
        },
        {
          text1: 'Inspirieren',
          text2: 'Gute Teams sind mehr als die Summe ihrer Einzelkönner',
        },
        {
          text1: 'Vorausschauen',
          text2: 'Organisationsentwicklung mit Augenmaß',
        },
      ] as Slide[],
      currentSlide: 0,
    };
  },

  created() {
    setInterval(this.nextSlide, 6000);
  },

  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
  },
});
