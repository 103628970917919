









import Vue from 'vue';
import { Slide } from '@/types';

export default Vue.extend({

  data() {
    return {
      slides: [
        {
          text1: 'Veränderung steuern und sich verändern gehören zusammen',
          text2: '- frei nach Dudley Lynch und Paul Kordis',
        },
        {
          text1: 'Es ist ein schlechtes Gedächtnis, das nur rückwärts gerichtet funktioniert.',
          text2: '- Lewis Caroll',
        },
        {
          text1: 'Je planmäßiger ein Mensch vorgeht, desto wirksamer vermag ihn der Zufall zu treffen.',
          text2: '- Friedrich Dürrenmatt',
        },
      ] as Slide[],
      currentSlide: 0,
    };
  },

  created() {
    setInterval(this.nextSlide, 6000);
  },

  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
  },
});
